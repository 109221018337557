import React, { useState } from 'react';
import classNames from 'classnames';

import { FieldCheckbox } from '../../../components';
import { parseSelectFilterOptions } from '../../../util/search';

import FilterPlain from '../FilterPlain/FilterPlain';
import FilterPopup from '../FilterPopup/FilterPopup';

import css from './CategoryFilter.module.css';

const debounce = (mainFunction, delay) => {
  let timer;

  return function(...args) {
    clearTimeout(timer);

    timer = setTimeout(() => {
      mainFunction(...args);
    }, delay);
  };
};

const GroupOfFieldCheckboxes = props => {
  const { id, className, options } = props;

  return (
    <fieldset className={className}>
      <ul className={css.list}>
        {options.map(({ key, label, type }, i) => {
          const fieldId = `${id}.${key}-${i}`;

          return (
            <li key={fieldId} className={classNames(css.item, {[css.indent]: type === "subCategory"})}>
              <FieldCheckbox id={fieldId} name={`pub_${type}`} label={label} value={key} />
            </li>
          );
        })}
      </ul>
    </fieldset>
  );
};

const PopupSearchBar = ({label, handleSearch}) => {
  return (
    <div className={css.popupSearchBar}>
      <span className={css.popupSearchBarLabel}>{label}</span>
      <input
        type='text'
        onChange={(e) => handleSearch(e.target.value)}
        placeholder='Search category...'
        className={css.popupSearchBarInput}
      />
    </div>
  )
}

const format = (selectedOptions, queryParamName) => {
  const hasOptionsSelected = selectedOptions && selectedOptions.filter(Boolean).length > 0;
  const value = hasOptionsSelected ? `${selectedOptions.join(',')}` : null;
  return { [queryParamName]: value };
};

const CategoryFilter = ({
  intl,
  showAsPopup,
  rootClassName,
  className,
  id,
  label,
  contentPlacementOffset = 0,
  categoryFilterClassName,
  queryParamNames,
  options,
  initialValues,
  onSubmit,
  ...rest
}) => {
  const [searchCategory, setSearchCategory] = useState([]);
  const classes = classNames(rootClassName || css.root, className);

  const hasInitialValues = !!initialValues && queryParamNames.some(key => !!initialValues[key]);

  const selectedOptions = hasInitialValues
    ? queryParamNames
      .map(key => parseSelectFilterOptions(initialValues[key] ?? ""))
      .flat(1)
      .filter(Boolean)
    : [];

  const labelForPopup = hasInitialValues
    ? intl.formatMessage(
        { id: 'SelectMultipleFilter.labelSelected' },
        { labelText: label, count: selectedOptions.length }
      )
    : label;
  const labelSelectionForPlain = hasInitialValues
    ? intl.formatMessage(
        { id: 'SelectMultipleFilterPlainForm.labelSelected' },
        { count: selectedOptions.length }
      )
    : '';

  const namedInitialValues = queryParamNames.reduce((total, key) => {
    if (!initialValues[key]) {
      return total;
    }

    return {
      ...total,
      [key]: parseSelectFilterOptions(initialValues[key] ?? ""),
    }
  }, {});

  const handleSubmit = values => {
    const result = queryParamNames.reduce((total, key) => {
      const usedValue = values ? values[key] : values;
      return {
        ...total,
        ...format(usedValue, key)
      }
    }, {});

    onSubmit(result);
  };

  const handleSearch = (value) => {
    const result = options.filter(({ key }) => key.toLowerCase().includes(value.toLowerCase()));
    setSearchCategory(result);
  }
  const debouncedSearch = debounce(handleSearch, 450);

  return showAsPopup ? (
    <FilterPopup
      className={classes}
      rootClassName={rootClassName}
      popupClassName={css.popupSize}
      label={labelForPopup}
      isSelected={hasInitialValues}
      id={`${id}.popup`}
      showAsPopup
      contentPlacementOffset={contentPlacementOffset}
      onSubmit={handleSubmit}
      initialValues={namedInitialValues}
      keepDirtyOnReinitialize
      popupSearchBar={<PopupSearchBar label={labelForPopup} handleSearch={debouncedSearch} />}
      {...rest}
    >
      <GroupOfFieldCheckboxes
        className={css.fieldGroup}
        id={`${id}-checkbox-group`}
        options={searchCategory.length ? searchCategory : options}
      />
    </FilterPopup>
  ) : (
    <FilterPlain
      className={className}
      rootClassName={rootClassName}
      plainClassName={css.popupSize}
      label={label}
      labelSelection={labelSelectionForPlain}
      isSelected={hasInitialValues}
      id={`${id}.plain`}
      liveEdit
      contentPlacementOffset={{}}
      onSubmit={handleSubmit}
      initialValues={namedInitialValues}
      {...rest}
    >
      <GroupOfFieldCheckboxes
        className={css.fieldGroupPlain}
        id={`${id}-checkbox-group`}
        options={searchCategory.length ? searchCategory : options}
      />
    </FilterPlain>
  );
}

export default CategoryFilter;
